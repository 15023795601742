<script>
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
// import Editor from '@tinymce/tinymce-vue';

import generateSummaryDocument from '@/lib/agreement/generateSummary';
import permissions from '@/mixins/permissions';
import { analytics } from '../firebase';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

// const VUE_APP_TINY_MCE_API_KEY = process.env.VUE_APP_TINY_MCE_API_KEY;
import UsersIntegration from '../lib/UsersIntegration';
import getTrackChangesAdapter from '../lib/TrackChangesAdapter'
import getCommentsAdapter from '../lib/CommentsAdapter'
import { hasEditPermission, hasViewPermission } from '@/mixins/roleLevelPermissions';
import emailApiRequest from '@/lib/emailApiRequest';
import DownloadAgreementConfirmationModal from './DownloadAgreementConfirmationModal.vue';
import agreementTitle from '@/lib/agreement-title';


export default {
	components: { DownloadAgreementConfirmationModal },
	props: [
		'agreementData'
	],
	name: 'newSummary',
	mixins: [permissions],
	computed: {
		...mapState(['agreement', 'user', 'editedSummaryAgreement','currentAgreementRoleLevelName']),
		summaryData: {
			get: function () {
				if (this.editedSummaryAgreement) {
					return this.editedSummaryAgreement;
				} else {
					return generateSummaryDocument({
						agreementData: this.agreementData,
					});
				}
			},
			set: function (value) {
				this.summaryHtml = value;
			},
		},
		canDownload() {
			return this.editedSummaryAgreement;
		},
	},
	data() {
		return {
			isViewMode : !hasEditPermission('summary-page'),
			summaryHtml: '',
			// VUE_APP_TINY_MCE_API_KEY,
			classLevels: [
				'one',
				'two',
				'three',
				'four',
				'five',
				'six',
				'seven',
				'eight',
				'nine',
				'ten',
				'eleven',
			],
			summary: Editor,
			isViewMode :  !hasEditPermission('summary-page'),
			summaryEditorInstance: null,
			isNotifyClientBtnDisabled: false,
			showDownloadConfirmationModal: false
		};
	},
	mounted() {
		analytics.logEvent('screen_view', {
			firebase_screen: 'summary',
			firebase_screen_class: 'SummaryCollaboration',
		});
		this.$store.dispatch('fetchEditedSummaryAgreement', {
			id: this.$route.params.id,
		});
		if(!this.agreementData.hasOwnProperty('summaryComments')){
			this.$set(this.agreementData, 'summaryComments', []);
		}
		if(!this.agreementData.hasOwnProperty('summaryTrackedChanges')){
			this.$set(this.agreementData, 'summaryTrackedChanges', []);
		}
	},
	methods: {
		indent(summary) {
			const parent = summary.dom.getParent(
				summary.selection.getNode(),
				'ol'
			);
			this.classLevels.some((className, index) => {
				if (summary.dom.hasClass(parent, className)) {
					summary.dom.removeAllAttribs(parent, true);
					summary.dom.removeClass(parent, className);
					summary.dom.addClass(parent, this.classLevels[index + 1]);

					summary.save()
					return true;
				}
			});
		},
		initEditor(_, summary) {
			summary.on('keydown', (e) => {
				if (e.key === 'Tab' && e.shiftKey === false) {
					e.preventDefault();

					this.indent(summary);
					return false;
				}
				// else if (e.key === 'Tab' && e.shiftKey === true) {
				// 	e.preventDefault();
				// 	return false;
				// }
			});

			summary.on('ExecCommand', (e) => {
				if (e.command === 'indent') {
					e.preventDefault();

					this.indent(summary);
					return false;
				}
				// else if (e.command === 'outdent') {
				// 	e.preventDefault();
				// 	return false;
				// }
			});
		},
		onExecCommand() {},
		indentInCK(summary) {
			summary.model.change( writer => {
				writer.insertText( 'foo', summary.model.document.selection.getFirstPosition() );
			} );
			const findAncestor = summary.editing.view.attributeelement.parent;
			// const parent = summary.dom.getParent(
			// 	summary.selection.getNode(),
			// 	'ol'
			// );
			// this.classLevels.some((className, index) => {
			// 	if (summary.dom.hasClass(parent, className)) {
			// 		summary.dom.removeAllAttribs(parent, true);
			// 		summary.dom.removeClass(parent, className);
			// 		summary.dom.addClass(parent, this.classLevels[index + 1]);

			// 		summary.save()
			// 		return true;
			// 	}
			// });
		},
		initSummaryInCK(summary) {
			summary.editing.view.document.on( 'keydown', ( event, data ) => {
				if (data.keyCode === 9 && data.shiftKey === false) {
					// console.log('Tab')
					data.preventDefault();
					this.indentInCK(summary);
					return false;
				}
			}, { priority: 'high' } );
		},
		initSummaryInCKUpdated(summary){
			let classesWhilePressingEnter;
			// summary.commands.get( 'enter' ).on( 'execute', () => {
			// summary.model.change( writer => {
			// const position = summary.model.document.selection.getFirstPosition();
			// writer.insertText( ';', position );
			// } );

			// const selectedBlocks = Array.from( summary.model.document.selection.getSelectedBlocks() );
			// const firstSelectedBlock = selectedBlocks[ 0 ];

			// if ( firstSelectedBlock && firstSelectedBlock.hasAttribute( 'listType' ) ) {
			// 	classesWhilePressingEnter = firstSelectedBlock.getAttribute( 'htmlListAttributes' ).classes;
			// } else {
			// 	classesWhilePressingEnter;
			// }
			// }, { priority: 'high' } );
			this.summaryEditorInstance = summary
			this.$nextTick(async () => {
                const editedSummaryWithDiscardedSuggestions = 
                    await summary.plugins
                        .get('TrackChangesData')
                        .getDataWithDiscardedSuggestions();

                this.$store.dispatch(
                    'updateEditedSummaryAgreementWithDiscardedSuggestions',
                    editedSummaryWithDiscardedSuggestions
                );
            });

			if(this.currentAgreementRoleLevelName !== 'lawyer-level-1'){
				summary.commands.get('trackChanges').execute();
			}
			const bodyWrapper = document.querySelector('.ck-body-wrapper');
            if (bodyWrapper && this.isViewMode=== true) {
                bodyWrapper.classList.add('summary-view-mode');
            }
			
			summary.editing.view.document.on( 'keydown', ( event, data ) => {
				if (data.keyCode === 9 && data.shiftKey === false) {
					data.preventDefault();
					summary.execute( 'indentList' );
					if (classesWhilePressingEnter){
						this.classLevels.some((className, index) => {
							if (classesWhilePressingEnter.includes(className) ) {
								const selectedBlocks = Array.from( summary.model.document.selection.getSelectedBlocks() );
								const firstSelectedBlock = selectedBlocks[ 0 ];

								if ( firstSelectedBlock && firstSelectedBlock.hasAttribute( 'listType' ) ) {
									summary.model.change( writer => {
									const htmlListAttributes = firstSelectedBlock.getAttribute( 'htmlListAttributes' );

								if ( htmlListAttributes.classes ) {
									htmlListAttributes.push( this.classLevels[index + 1] );
								} else {
									htmlListAttributes.classes = [this.classLevels[index + 1]];
								}
									writer.setAttribute( 'htmlListAttributes', htmlListAttributes, firstSelectedBlock );
								} );

									summary.editing.reconvertItem( firstSelectedBlock );
								}
							}
						})
					}
				} else if(data.keyCode === 13){
					const selectedBlocks = Array.from( summary.model.document.selection.getSelectedBlocks() );
					const firstSelectedBlock = selectedBlocks[ 0 ];

					if ( firstSelectedBlock && firstSelectedBlock.hasAttribute( 'listType' ) ) {
						classesWhilePressingEnter = firstSelectedBlock.getAttribute( 'htmlListAttributes' ).classes;
					} else {
						classesWhilePressingEnter;
					}
				}
			}, { priority: 'high' } );
		},
		onExecCommand() {},
		async save() {
			this.$store
				.dispatch('updateEditedSummaryAgreement', this.summaryHtml)
				.then(() => {
					analytics.logEvent('edited_agreement_saved');
				});
			let editedSummaryWithDiscardedSuggestions = await this.summaryEditorInstance.plugins.get('TrackChangesData').getDataWithDiscardedSuggestions()
			this.$store.dispatch('updateEditedSummaryAgreementWithDiscardedSuggestions', editedSummaryWithDiscardedSuggestions)
		},
		download() {
			window.open(
				`${window.location.origin}${window.location.pathname}/summary`
			);
		},
		downloadSummary(){
			const trackChangesPlugin = this.summaryEditorInstance.plugins.get( 'TrackChanges' );
			let unresolvedChanges = trackChangesPlugin.getSuggestions( { skipNotAttached: true, toJSON: true } );
			const commentsRepository = this.summaryEditorInstance.plugins.get('CommentsRepository');
			const commentThreadsData = commentsRepository.getCommentThreads({
				skipNotAttached: true,
				skipEmpty: true,
				toJSON: true,
			});
			const unresolvedComments = commentThreadsData.filter((comments)=> !comments.resolvedBy && !comments.resolvedAt)
			if(unresolvedChanges.length > 0 || unresolvedComments.length > 0){
				this.showDownloadConfirmationModal = true
			}
			else{
				this.showDownloadConfirmationModal = false
				window.open(
					`${window.location.origin}${window.location.pathname}/summary?draft=true&download=summary`
				);
			}
            console.log("test download:", `${window.location.origin}${window.location.pathname}/summary?draft=true?download=summary`)
			
		},
		update() {
			const newElement = this.$createElement;
			const messageConent = newElement(
				'div',
				{ class: ['text-center'] },
				[
					newElement(
						'p',
						{},
						'Updating will pull any new data from the Agreement Builder into the summary and any changes will be lost.'
					),
				]
			);
			this.$bvModal
				.msgBoxConfirm([messageConent], {
					title: 'Heads Up!',
					size: 'md',
					buttonSize: 'sm',
					okVariant: 'primary',
					okTitle: 'I Understand',
					cancelTitle: "I'm not ready",
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true,
				})
				.then(async (accepted) => {
					if (accepted) {
						const updatedAgreement = generateSummaryDocument({
							agreementData: this.agreementData,
						});
						this.summaryHtml = updatedAgreement;
						this.$store.dispatch(
							'updateEditedSummaryAgreement',
							updatedAgreement
						);
						analytics.logEvent('edited_agreement_updated');
						let editedSummaryWithDiscardedSuggestions = await this.summaryEditorInstance.plugins.get('TrackChangesData').getDataWithDiscardedSuggestions()
						this.$store.dispatch('updateEditedSummaryAgreementWithDiscardedSuggestions', editedSummaryWithDiscardedSuggestions)
					}
				})
				.catch(() => {});
		},
		proceedToEditor(){
			this.$emit('openEditor');
		},
		getEditorConfig(){
			if (this.isViewMode) {
				return {
					toolbar: ['comment'],
					extraPlugins: [
						UsersIntegration(this.agreementData,'summary'),
						getCommentsAdapter(this.agreementData,'summary'),
						getTrackChangesAdapter(this.agreementData,'summary')
					],
					htmlSupport: {
						allow: [
							{
								name: 'div',
								attributes: true,
								classes: true,
								id:true
							},
							{
								name: 'span',
								attributes: true,
								classes: true
							},
							{
								name: 'p',
								attributes: true,
								classes: true
							},
							{
								name: 'li',
								attributes: true,
								classes: true
							},
							{
								name: 'ol',
								attributes: true,
								classes: true 
							},
							{
								name: 'b',
								attributes: true,
								classes: true 
							},
							{
								name: 'i'
							},
							{
								name: 'table',
								attributes: true,
								classes: true,
							},
							{
								name: 'tbody',
								attributes: true,
								classes: true ,
							},
							{
								name: 'tr',
								attributes: true,
								classes: true,
							},
							{
								name: 'td',
								attributes: true,
								classes: true,
							}
						]
					},
					autoParagraph :false,
					fillEmptyBlocks : false,
					highlight: {
						options: [
							{
								model: 'greenMarker',
								class: 'marker-green',
								title: 'Green marker',
								color: 'rgb(25, 156, 25)',
								type: 'marker'
							},
							{
								model: 'yellowMarker',
								class: 'marker-yellow',
								title: 'Yellow marker',
								color: '#cac407',
								type: 'marker'
							}
						]
					},
					table: {
						tableProperties: {
							// The default styles for tables in the editor.
							// They should be synchronized with the content styles.
							defaultProperties: {
								borderStyle: 'solid',
								borderColor: 'hsl(90, 75%, 60%)',
								borderWidth: '3px',
								alignment: 'left',
								width: '550px',
								height: '450px'
							},
							// The default styles for table cells in the editor.
							// They should be synchronized with the content styles.
							tableCellProperties: {
								defaultProperties: {
									horizontalAlignment: 'center',
									verticalAlignment: 'bottom',
									padding: '10px'
								}
							}
						}
					},
            	}
			}
			else{
				return {
					extraPlugins: [
						UsersIntegration(this.agreementData,'summary'),
						getCommentsAdapter(this.agreementData,'summary'),
						getTrackChangesAdapter(this.agreementData,'summary')
					],
					htmlSupport: {
						allow: [
							{
								name: 'div',
								attributes: true,
								classes: true,
								id:true
							},
							{
								name: 'span',
								attributes: true,
								classes: true
							},
							{
								name: 'p',
								attributes: true,
								classes: true
							},
							{
								name: 'li',
								attributes: true,
								classes: true
							},
							{
								name: 'ol',
								attributes: true,
								classes: true 
							},
							{
								name: 'b',
								attributes: true,
								classes: true 
							},
							{
								name: 'i'
							},
							{
								name: 'table',
								attributes: true,
								classes: true,
							},
							{
								name: 'tbody',
								attributes: true,
								classes: true ,
							},
							{
								name: 'tr',
								attributes: true,
								classes: true,
							},
							{
								name: 'td',
								attributes: true,
								classes: true,
							}
						]
					},
					autoParagraph :false,
					fillEmptyBlocks : false,
					highlight: {
						options: [
							{
								model: 'greenMarker',
								class: 'marker-green',
								title: 'Green marker',
								color: 'rgb(25, 156, 25)',
								type: 'marker'
							},
							{
								model: 'yellowMarker',
								class: 'marker-yellow',
								title: 'Yellow marker',
								color: '#cac407',
								type: 'marker'
							}
						]
					},
					table: {
						tableProperties: {
							// The default styles for tables in the editor.
							// They should be synchronized with the content styles.
							defaultProperties: {
								borderStyle: 'solid',
								borderColor: 'hsl(90, 75%, 60%)',
								borderWidth: '3px',
								alignment: 'left',
								width: '550px',
								height: '450px'
							},
							// The default styles for table cells in the editor.
							// They should be synchronized with the content styles.
							tableCellProperties: {
								defaultProperties: {
									horizontalAlignment: 'center',
									verticalAlignment: 'bottom',
									padding: '10px'
								}
							}
						}
					}
            	}
			}
			
		},
		async notifyOtherUsers(){
			let body={
				agreementId: this.agreement.id,
				host: window.location.host,
				UserEmail: this.user.email,
				UserName: this.user.displayName,
				agreementName: this.agreement && agreementTitle(this.agreement).co != 'and'
					? agreementTitle(this.agreement)
					: '',
				pageName: "Summary"
			}
			console.log('body',body);
			this.isNotifyClientBtnDisabled = true;
			try {
				await emailApiRequest('POST','/notifyEditorUpdateEmail',body).catch((err)=> console.log('notifyEditorUpdateEmail err->',err));
					this.isNotifyClientBtnDisabled = false;
			} catch (error) {
				this.isNotifyClientBtnDisabled = false;
			}
		},
		hasViewPermission
	},
	watch: {
		summaryHtml: {
			handler: debounce(function () {
				this.save();
			}, 1000),
		},
	},
};
</script>

<style>
@import '../assets/css/summaryStyles.css';

#container {
    /* To create the column layout. */
    display: flex;

    /* To make the container relative to its children. */
    position: relative;
    max-height: calc(100vh-100px);
}
/* ::marker{
	content: '';
} */

/* .one > li  br,.two > li br,.three > li br,.four > li br,.five > li br,.six > li br,.seven > li br,.eight > li br,.nine > li br{
	content: '';
} */

/* .seven > li > .ck-list-bogus-paragraph, .eight > li > .ck-list-bogus-paragraph{
	display: unset !important;
} */

.one > li::marker,.two > li::marker,.three > li::marker,.four > li::marker,.five > li::marker,.six > li::marker,.seven > li::marker,.eight > li::marker,.nine > li::marker{
	content: '';
}

.ck-editor__editable .ck-list-bogus-paragraph{
	display: unset !important;
}

.ck-editor__editable .ck-list-bogus-paragraph:has(> br){
	display: -webkit-inline-box !important;
}

p:empty {
  display:none
}

.ck-editor__top{
	
	position: sticky !important;
    top: 0px !important;
    z-index: 999 !important;
}

.ck-editor{
	margin-top: 1rem;
    overflow-y: scroll;
    max-height: 65vh;
}

.downloadSummary:active{
	background-color: rgb(108, 117, 125); 
}

.proceed-to-editor-btn{
	position: fixed; 
	bottom: 4rem; 
	right: 3.8rem;     
}
.notify-summary-client{
	position: fixed; 
	bottom: 4rem; 
	right: 15rem;      
}
.notify-summary{
	position: fixed; 
	bottom: 4rem; 
	right: 3.8rem; 
}

.summary-view-mode .ck-suggestion--accept,
.summary-view-mode .ck-suggestion--discard {
    display: none !important;
}
</style>

<template>
	<div id="summary">
		<b-card class="mt-2 custom-header-wrapper">
			<h4 slot="header">Summary</h4>
		</b-card>
		<b-row class="my-3">
			<b-col cols="7"></b-col>
			<b-col cols="5">
				<div class="float-right">
					<b-button-group data-testid="summary-buttons">
						<b-button
							class="downloadButton"
							variant="secondary"
							data-testid="summary-download-draft"
							@click="downloadSummary"
						>
							Download Summary
						</b-button>
						<b-button
							variant="secondary"
							@click="update"
							data-testid="update-summary-page"
						>
							Update
						</b-button>
						<!-- <b-button
							variant="primary"
							@click="exportToClio"
							data-testid="export-to-clio"
						>
							Export To Clio
						</b-button> -->
						<!-- <b-button
							variant="primary"
							:disabled="!canDownload"
							@click="download"
							data-testid="summary-download"
						>
							Download
						</b-button> -->
					</b-button-group>
					
				</div>
			</b-col>
		</b-row>
		<div v-if="summaryData" class="summary">
			
			<ckeditor :editor="summary" v-model="summaryData" :config="getEditorConfig()" 
	  			@ready="initSummaryInCKUpdated"></ckeditor>

			<!-- <editor
				v-model="summaryData"
				:api-key="VUE_APP_TINY_MCE_API_KEY"
				:init="{
					menubar: '',
					plugins: [
						'advlist autolink lists link image charmap print preview anchor',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime media table paste code help wordcount',
					],
					toolbar:
						'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
					browser_spellcheck: true,
					contextmenu: false,
				}"
				:inline="true"
				@onInit="initEditor"
				@onExecCommand="onExecCommand"
			/> -->
		</div>
		<div
			v-else
			class="
				text-center
				w-100
				d-flex
				justify-content-center
				align-items-center
			"
			style="height: 500px"
		>
			<b-spinner
				style="width: 3rem; height: 3rem"
				variant="primary"
				label="Loading..."
			/>
		</div>
		<download-agreement-confirmation-modal
			:showDownloadConfirmationModal="showDownloadConfirmationModal"
			@close="showDownloadConfirmationModal = false"
			:mode="'summary'"
		/>
		<b-button variant="primary" :class="[hasViewPermission('editor') ? 'notify-summary-client' :'notify-summary']" @click="notifyOtherUsers" :disabled="isNotifyClientBtnDisabled">
			<template v-if="isNotifyClientBtnDisabled">
				<b-spinner small type="grow"></b-spinner>
				Notifying...
			</template>
			<template v-else>
				Notify
			</template>
		</b-button>
		<b-button v-if="hasViewPermission('editor')" variant="primary" class="proceed-to-editor-btn" @click="proceedToEditor">Proceed To Editor</b-button>
	</div>
</template>
