import { mapState } from 'vuex';
import { debounce } from 'lodash';

export default {
	data() {
		return {
			inactivityTimeout: null,
			warningTimeout: null,
			logoutDelay: 30 * 60 * 1000, 
			warningDelay: 25 * 60 * 1000, 
			events: [
				'mousedown',
				'mousemove',
				'keypress',
				'scroll',
				'touchstart',
                'click'
			],
            debouncedReset: null,

		};
	},

	computed: {
		...mapState(['currentRoleLevel']),
		isAuthenticated() {
			return this.$store.getters.isAuthenticated;
		},
	},

	watch: {
		isAuthenticated(newVal) {
			if (newVal) {
				this.setupInactivityCheck();
			}
			  else {
			    this.cleanupInactivityCheck();
			  }
		},
	},

	beforeDestroy() {
		this.cleanupInactivityCheck();
	},

	methods: {
		setupInactivityCheck() {            
            this.debouncedReset = debounce(this.resetInactivityTimer, 100);
            
            this.events.forEach((event) => {
                window.addEventListener(event, this.debouncedReset);
            });

			this.resetInactivityTimer();
		},

		resetInactivityTimer() {
			clearTimeout(this.inactivityTimeout);
			clearTimeout(this.warningTimeout);

			this.warningTimeout = setTimeout(() => {
				this.showWarningToast();
			}, this.warningDelay);

			this.inactivityTimeout = setTimeout(() => {
				this.logoutUser();
			}, this.logoutDelay);
		},

		logoutUser() {
			try {
				const currentUserRole = this.currentRoleLevel?.roleAssociated; 

				this.$store.dispatch('logout').then(() => {

					this.$bvToast.toast(
						'You have been logged out due to inactivity',
						{
							title: 'Session Timeout',
							variant: 'warning',
							solid: true,
						}
					);

					if (currentUserRole === 'party') {
						this.$router.push({ path: '/couple/auth' });
					} else {
						this.$router.push({ path: '/auth' });
					}
					this.$store.commit('setLoggingOut', false);
				});

			} catch (error) {
				console.error('Logout error:', error);
				this.$toast.error('Error during logout. Please try again.', {
					duration: 5000,
					position: 'top-right',
				});
			}
		},

		showWarningToast() {
			this.$bvToast.toast('Your session will expire in 5 minutes due to inactivity', {
				title: 'Session Expiring Soon',
				variant: 'warning',
				solid: true,
			});
		},


		cleanupInactivityCheck() {
            if (this.debouncedReset) {
                this.events.forEach((event) => {
                    window.removeEventListener(event, this.debouncedReset);
                });
                this.debouncedReset.cancel();
                this.debouncedReset = null;
            }

            clearTimeout(this.inactivityTimeout);
            clearTimeout(this.warningTimeout);
            this.inactivityTimeout = null;
            this.warningTimeout = null;
		},
	},
};
